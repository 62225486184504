var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"content"},[_c('div',{staticStyle:{"background":"#fff"}},[_c('div',{staticClass:"domrelative wrapper_1200"},[_vm._m(2),_c('div',{staticClass:"domUn domFexidTop",style:(`height:` + _vm.maxHigth + `px`)},[_vm._m(3),_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/12/政企通切图01-3pcncg1z62tx.png","alt":""}})])])]),_c('div',[_c('div',{staticClass:"domrelative wrapper_1200"},[_vm._m(4),_c('div',{staticClass:"domUn domFexidTop",style:(`height:` + _vm.maxHigth + `px`)},[_vm._m(5),_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/政企通切图02-djxp7r6avam5.png","alt":""}})])])]),_c('div',{staticStyle:{"background":"#fff"}},[_c('div',{staticClass:"domrelative wrapper_1200"},[_vm._m(6),_c('div',{staticClass:"domUn domFexidTop",style:(`height:` + _vm.maxHigth + `px`)},[_vm._m(7),_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/12/政企通切图03-042okwgq1vr8.png","alt":""}})])])]),_c('div',[_c('div',{staticClass:"domrelative wrapper_1200"},[_vm._m(8),_c('div',{staticClass:"domUn domFexidTop",style:(`height:` + _vm.maxHigth + `px`)},[_vm._m(9),_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/12/政企通切图04-k7rzm67plyt9.png","alt":""}})])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"topImg"},[_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/01/政企通-prbspx6pi6lm.png","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background":"#fff"}},[_c('div',{staticClass:"wrapper_1200 dom1"},[_c('p',{staticClass:"title"},[_vm._v("政企通SaaS")]),_c('p',{staticClass:"titleText"},[_c('span',[_vm._v("该平台围绕企事业单位等终端业务场景，是集采购及供应商管理为一体，为企事业单位等终端客户打造从供应、采购到交付的一站式采购管理平台，帮助企事业单位实现电子化、信息化采购流程，提升企事业单位竞争优势。")]),_c('span',{staticClass:"icon1"},[_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/01/Vector-821c0ssjruuc.png","alt":""}})]),_c('span',{staticClass:"icon2"},[_c('img',{attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/01/Vectorxx1x-4dstvlpteupa.png","alt":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"domup"},[_c('img',{staticStyle:{"width":"100%","height":"2050px"},attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/41281694153461_xpic-x64zn5yq0dr6.jpg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("核心功能一")]),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"domup"},[_c('img',{staticStyle:{"width":"100%","height":"2050px"},attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/41281694153461_xpic-x64zn5yq0dr6.jpg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("核心功能二")]),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"domup"},[_c('img',{staticStyle:{"width":"100%","height":"2050px"},attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/41281694153461_xpic-x64zn5yq0dr6.jpg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("核心功能三")]),_c('span')])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"domup"},[_c('img',{staticStyle:{"width":"100%","height":"2050px"},attrs:{"src":"https://oss.pinmallzj.com/image/maintain/2023/09/08/41281694153461_xpic-x64zn5yq0dr6.jpg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',[_vm._v("核心功能四")]),_c('span')])
}]

export { render, staticRenderFns }